var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"font-weight-bolder mb-2"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",attrs:{"variant":"white","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" Crear cliente ")]),_c('b-card',[_c('validation-observer',{ref:"createClient"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre',"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre'},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Alias',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Alias',"label-for":"client-alias"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Alias'},model:{value:(_vm.alias),callback:function ($$v) {_vm.alias=$$v},expression:"alias"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Pais',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Pais',"label-for":"client-country"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Pais'},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Ciudad',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Ciudad',"label-for":"client-city"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Ciudad'},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Código postal',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Código postal',"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Código postal'},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Dirección',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Dirección',"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Dirección'},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'CIF',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'CIF',"label-for":"client-cif"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'CIF'},model:{value:(_vm.cif),callback:function ($$v) {_vm.cif=$$v},expression:"cif"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Telefono',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Telefono',"label-for":"client-phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Telefono'},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Email',"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email',"label-for":"client-email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Email'},model:{value:(_vm.emailInput),callback:function ($$v) {_vm.emailInput=$$v},expression:"emailInput"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":'Web',"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Web'},model:{value:(_vm.web),callback:function ($$v) {_vm.web=$$v},expression:"web"}})],1),_c('b-form-group',{attrs:{"label":'Imagen',"label-for":"client-image"}},[_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.files,"maxFiles":"1"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Comentarios',"label-for":"client-comments"}},[_c('quill-editor',{model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('h4',[_vm._v("Datos de contacto")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{attrs:{"name":'Persona de contacto',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Persona de contacto',"label-for":"client-contact_person"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Persona de contacto'},model:{value:(_vm.contact_person),callback:function ($$v) {_vm.contact_person=$$v},expression:"contact_person"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{attrs:{"name":'Email de contacto',"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Email de contacto',"label-for":"client-contact_person_email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Email de contacto'},model:{value:(_vm.contact_person_email),callback:function ($$v) {_vm.contact_person_email=$$v},expression:"contact_person_email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"4"}},[_c('validation-provider',{attrs:{"name":'Teléfono de contacto',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Teléfono de contacto',"label-for":"client-contact_person_phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Teléfono de contacto'},model:{value:(_vm.contact_person_phone),callback:function ($$v) {_vm.contact_person_phone=$$v},expression:"contact_person_phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s('Enviar')+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }