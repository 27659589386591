<template>
  <div>
    <h2 class="font-weight-bolder mb-2" >
      <span @click="$router.go(-1)" >
        <b-button
          variant="white"
          class="btn-icon rounded-circle mr-50 text-danger bg-white"
          size="sm"
        >
          <feather-icon icon="ChevronLeftIcon" />
        </b-button>
      </span> Crear cliente
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createClient">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group
                  :label="'Nombre'"
                  label-for="client-name"
                >
                  <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                :name="'Alias'"
                rules="required"
              >
                <b-form-group
                  :label="'Alias'"
                  label-for="client-alias"
                >
                  <b-form-input
                    v-model="alias"
                    name="name"
                    :placeholder="'Alias'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  #default="{ errors }"
                  :name="'Pais'"
                  rules="required"
              >
                <b-form-group
                    :label="'Pais'"
                    label-for="client-country"
                >
                  <b-form-input
                      v-model="country"
                      name="reference"
                      :placeholder="'Pais'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  #default="{ errors }"
                  :name="'Ciudad'"
                  rules="required"
              >
                <b-form-group
                    :label="'Ciudad'"
                    label-for="client-city"
                >
                  <b-form-input
                      v-model="city"
                      name="reference"
                      :placeholder="'Ciudad'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  #default="{ errors }"
                  :name="'Código postal'"
                  rules="required"
              >
                <b-form-group
                    :label="'Código postal'"
                    label-for="client-postalCode"
                >
                  <b-form-input
                      v-model="postalCode"
                      name="reference"
                      :placeholder="'Código postal'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  #default="{ errors }"
                  :name="'Dirección'"
                  rules="required"
              >
                <b-form-group
                    :label="'Dirección'"
                    label-for="client-address"
                >
                  <b-form-input
                      v-model="address"
                      name="reference"
                      :placeholder="'Dirección'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'CIF'"
                  rules="required"
              >
                <b-form-group
                    :label="'CIF'"
                    label-for="client-cif"
                >
                  <b-form-input
                      v-model="cif"
                      name="reference"
                      :placeholder="'CIF'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  #default="{ errors }"
                  :name="'Telefono'"
                  rules="required"
              >
                <b-form-group
                    :label="'Telefono'"
                    label-for="client-phone"
                >
                  <b-form-input
                      v-model="phone"
                      name="reference"
                      :placeholder="'Telefono'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                  #default="{ errors }"
                  :name="'Email'"
                  rules="email|required"
              >
                <b-form-group
                    :label="'Email'"
                    label-for="client-email"
                >
                  <b-form-input
                      v-model="emailInput"
                      name="reference"
                      :placeholder="'Email'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group
                  :label="'Web'"
                  label-for="client-web"
              >
                <b-form-input
                    v-model="web"
                    name="reference"
                    :placeholder="'Web'"
                />
              </b-form-group>
              <b-form-group
                  :label="'Imagen'"
                  label-for="client-image"
              >
                <ImageDropzone
                    ref="image"
                    :files="files"
                    maxFiles="1"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" >
              <b-form-group
                  :label="'Comentarios'"
                  label-for="client-comments"
              >
                <quill-editor
                    v-model="comments"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2" >
              <h4>Datos de contacto</h4>
            </b-col>
            <b-col sm="4">
              <validation-provider
                  #default="{ errors }"
                  :name="'Persona de contacto'"
                  rules="required"
              >
                <b-form-group
                    :label="'Persona de contacto'"
                    label-for="client-contact_person"
                >
                  <b-form-input
                      v-model="contact_person"
                      name="reference"
                      :placeholder="'Persona de contacto'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="4">
              <validation-provider
                  #default="{ errors }"
                  :name="'Email de contacto'"
                  rules="email|required"
              >
                <b-form-group
                    :label="'Email de contacto'"
                    label-for="client-contact_person_email"
                >
                  <b-form-input
                      v-model="contact_person_email"
                      name="reference"
                      :placeholder="'Email de contacto'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="4">
              <validation-provider
                  #default="{ errors }"
                  :name="'Teléfono de contacto'"
                  rules="required"
              >
                <b-form-group
                    :label="'Teléfono de contacto'"
                    label-for="client-contact_person_phone"
                >
                  <b-form-input
                      v-model="contact_person_phone"
                      name="reference"
                      :placeholder="'Teléfono de contacto'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    BLink,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      alias: '',
      cif: '',
      address: '',
      postalCode: '',
      city: '',
      emailInput: '',
      web: '',
      prone: '',
      country: '',
      comments: '',
      contact_person: '',
      contact_person_email: '',
      contact_person_phone: '',
      files: [],
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      client: 'clients/getClient',
    }),
  },
  methods: {
    ...mapActions({
      create: 'clients/create',
      getClient: 'clients/getClient',
    }),
    handleSubmit() {
      this.$refs.createClient.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ client: formData })
          }
        }
      })
    },
    createFormData() {
      const data = this.$refs.image.getFormData('image')

      data.append('name', this.name)
      data.append('alias', this.alias)
      data.append('cif', this.cif)
      data.append('address', this.address)
      data.append('postalCode', this.postalCode)
      data.append('country', this.country)
      data.append('city', this.city)
      data.append('phone', this.phone)
      data.append('email', this.emailInput)
      data.append('web', this.web)
      data.append('comments', this.comments)
      data.append('contact_person', this.contact_person)
      data.append('contact_person_email', this.contact_person_email)
      data.append('contact_person_phone', this.contact_person_phone)

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
